<template>
  <div class="admin-wizard">
    <AdminHeader />
    <AdminBreadcrumb @on-saveContinue="onFormSubmit(true)" />
    <main class="content">
      <b-container>
        <b-row>
          <b-col md="3">
            <AdminSideNav />
          </b-col>
          <b-col md="9">
            <ValidationObserver ref="observer" v-slot="{ invalid }">
              <b-card class="media-block">
                <b-card-body class="p-0">
                  <b-card-title>
                    What photo(s) would you like to include on your site?
                    <span class="font-weight-normal">(Optional)</span>
                  </b-card-title>
                  <div class="guide-tip">
                    <b-row align-v="center">
                      <b-col cols="3" lg="1" class="icon">
                        <font-awesome-icon :icon="['fas', 'images']" />
                      </b-col>
                      <b-col
                        cols="12"
                        lg="8"
                        order="3"
                        order-lg="2"
                        class="text"
                      >
                        <div class="mb-2">
                          You can only upload JPG, GIF, PNG files. Maximum
                          upload file size is 5 MB.
                        </div>
                        When uploading more than one photo, your photos will be
                        displayed in a slideshow.
                        <div></div>
                      </b-col>
                      <b-col
                        cols="9"
                        lg="3"
                        order="2"
                        order-lg="3"
                        class="button"
                      >
                        <b-button
                          variant="outline-secondary"
                          size="sm"
                          v-b-modal.modal-theme-guide
                        >
                          View Example
                          <font-awesome-icon
                            :icon="['far', 'window-maximize']"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                  <b-alert :show="!!errorMessage" variant="danger">{{
                    errorMessage
                  }}</b-alert>
                  <ValidationProvider name="Media Images">
                    <div
                      v-for="(photo, index) in photoList"
                      :key="`${index}-${photo}`"
                    >
                      <b-row class="mt-4">
                        <b-col md="8">
                          <label>File Upload</label>
                          <b-form-file
                            accept="image/*"
                            placeholder="Select a photo"
                            v-model="photo.file"
                          ></b-form-file>
                        </b-col>
                        <b-col md="4">
                          <b-button
                            class="remove-btn"
                            v-show="!!photo.file"
                            variant="outline-danger"
                            @click="removePhoto(index)"
                            >Remove <font-awesome-icon :icon="['fas', 'trash-alt']"
                            /></b-button
                          >
                        </b-col>
                      </b-row>
                      <b-row class="mt-3">
                        <b-col md="8">
                          <label
                            >Photo Caption
                            <span class="font-weight-normal"
                              >(Optional)</span
                            ></label
                          >
                          <b-form-input
                            v-model="photo.caption"
                            placeholder="Enter your caption"
                          ></b-form-input>
                        </b-col>
                      </b-row>
                      <hr class="my-4" />
                    </div>
                  </ValidationProvider>
                  <b-row>
                    <b-col md="12">
                      <b-button @click="addMorePhoto" variant="primary"
                        >Add Additional Photo</b-button
                      >
                    </b-col>
                  </b-row>

                  <b-card-title class="mt-5">
                    What video(s) would you like to include on your site?
                    <span class="font-weight-normal">(Optional)</span>
                  </b-card-title>
                  <div class="guide-tip">
                    <b-row align-v="center">
                      <b-col cols="3" lg="1" class="icon">
                        <font-awesome-icon :icon="['fas', 'photo-video']" />
                      </b-col>
                      <b-col cols="12"
                             lg="8"
                             order="3"
                             order-lg="2"
                             class="text">
                        The URL for your video can be from YouTube, Vimeo, Instagram, or a link to an MP4, OGV, WebM video file hosted elsewhere.
                      </b-col>
                      <b-col
                        cols="9"
                        lg="3"
                        order="2"
                        order-lg="3"
                        class="button"
                      >
                        <b-button
                          variant="outline-secondary"
                          size="sm"
                          v-b-modal.modal-theme-guide
                        >
                          View Example
                          <font-awesome-icon
                            :icon="['far', 'window-maximize']"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                  <b-alert :show="!!errorMessage" variant="danger">{{
                    errorMessage
                  }}</b-alert>
                  <ValidationProvider name="Media URLs">
                    <div v-for="(video, index) in videoList" :key="index">
                      <b-row class="mt-4">
                        <b-col md="8">
                          <label>Video URL</label>
                          <b-form-input
                            type="url"
                            v-model="video.url"
                            placeholder="https://www.youtube.com/watch?v=abc1234"
                          ></b-form-input>
                        </b-col>
                        <b-col md="4">
                          <b-button
                            class="remove-btn"
                            v-show="!!video.url"
                            variant="outline-danger"
                            @click="removeVideo(index)"
                            >Remove <font-awesome-icon :icon="['fas', 'trash-alt']"
                            /></b-button
                          >
                        </b-col>
                      </b-row>
                      <b-row class="mt-3">
                        <b-col md="8">
                          <label
                            >Video Caption
                            <span class="font-weight-normal"
                              >(Optional)</span
                            ></label
                          >
                          <b-form-input
                            v-model="video.caption"
                            placeholder="Enter your caption"
                          ></b-form-input>
                        </b-col>
                      </b-row>
                      <hr class="my-4" />
                    </div>
                  </ValidationProvider>
                  <b-row>
                    <b-col md="12">
                      <b-button @click="addMoreVideo" variant="primary"
                        >Add Additional Video</b-button
                      >
                    </b-col>
                  </b-row>
                </b-card-body>
              </b-card>
              <AdminFooterButton
                @on-save="onFormSubmit"
                @on-back="onBack"
                @on-preview="onPreview"
                @on-skip="onSkip"
                :saving="saving"
                :invalid="invalid"
              />
            </ValidationObserver>
          </b-col>
        </b-row>
      </b-container>
    </main>
    <ModalThemeGuide
      theme-id="mediaBlock"
      :template-id="candidateModel.profileTheme"
    />
  </div>
</template>

<script>
import DirtyFlagMixin from "@/mixins/dirtyflag.mixin";
import CandidateMixin from "@/mixins/candidate.mixin";
import imageCompression from 'browser-image-compression';

export default {
  name: "AboutMediaBlock",
  mixins: [DirtyFlagMixin, CandidateMixin],
  data() {
    return {
      photoList: [{ file: null, caption: null }],
      videoList: [{ url: null, caption: null }]
    };
  },
  methods: {
    addMorePhoto() {
      this.photoList.push({
        file: null,
        caption: null
      });
    },
    addMoreVideo() {
      this.videoList.push({
        url: null,
        caption: null
      });
    },
    removePhoto(index) {
      this.photoList.splice(index, 1);
    },
    removeVideo(index) {
      this.videoList.splice(index, 1);
    },
    onPreview() {},
    onBack() {
      this.$router.push("feature-text");
    },
    onSkip() {
      if (this.candidateModel.medias.length > 0)
        this.$router.push("manage-media");
      else this.$router.push("social-links");
    },
    onSave() {
      this.$refs.submit.click();
    },
    async onFormSubmit(fromBreadcrumb) {
      const compressOptions = {
        maxSizeMB: 3,
        useWebWorker: true,
      }

      this.isSubmitting = true;
      this.saving = true;

      const compressedPhotos = [];

      for (let index = 0; index < this.photoList.length; index++) {
        const photo = this.photoList[index];
        var compressedFile = await imageCompression(photo.file, compressOptions)
        compressedPhotos.push({ file: compressedFile, caption: photo.caption, fileName: compressedFile.name})
      }

      let payload = {
        userId: this.$route.params.id,
        photos: compressedPhotos,
        videos: this.videoList
      };

      this.$refs.observer.validate().then(valid => {
        if (valid) {
          this.candidateService
            .SaveMediaAsync(payload)
            .then(res => {
              this.$store.commit("candidates/SET_CANDIDATE", res.data);
              setTimeout(() => {
                this.saving = false;
                if (fromBreadcrumb != true) {
                  if (this.candidateModel.medias.length > 0)
                    this.$router.push("manage-media");
                  else this.$router.push("social-links");
                }
              }, 500);
            })
            .catch(e => {
              this.saving = false;
              this.errorMessage = e;
            });
        } else {
          this.saving = false;
          this.errorMessage = "Please fill up required fields!";
        }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
@import "@/assets/styles/admin/_base.scss";

.admin-wizard .media-block {
  label {
    font-weight: 600;
  }

  .remove-btn {
    margin-top: 30px;
    border-width: 0;

    @media (max-width: $md-width) {
      & {
        margin-top: 15px;
      }
    }
  }
}
</style>
